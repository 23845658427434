<template>
  <div >
    <div class="login-container">
      <div class="content">
        <img src="../../assets/logotype.svg" alt="logo">
        <h1>Device Monitoring</h1>
          <div style="margin-left:100px;" class="pl-10 ml-10" ref="googleLoginBtn" />
      </div>
      
      <div class="background" />
    </div>
    <div class="footer">
      <span>Copyright © 2018 by NSoft. All Rights Reserved</span>
    </div>
    <notifications group="alerts" />

  </div>
</template>

<script>
import axios from "axios"

export default {
  name: 'Login',
  data() {
    return {
      
    }
  },
  methods: {
      onWindowLoad(){
      // Load the auth2 library
        const gClinetId = "344325573867-bj6ucm81mkfukr8825arka6i4fpmdmei.apps.googleusercontent.com";
        window.google.accounts.id.initialize({
          client_id: gClinetId,
          callback: this.handleCredentialResponse,
          auto_select: true
        });
        window.google.accounts.id.renderButton(
          this.$refs.googleLoginBtn, {
            text: "Login",
            size: "large",
            width: 300,//changed to integer 
            theme: "outline",
            type: "standard",
          }
        );
      },
      handleCredentialResponse(response) {
        axios.post(`${process.env.VUE_APP_API_ROOT}/auth/login`, {
          token: response.credential
        }).then(response => {
          localStorage.setItem('token', response.data.token);
          window.location.replace('/');
        }).catch((err) => {
          if (err.response.status === 401) {
            this.$notify({
              group: "alerts",
              type: "error",
              title: "Not allowed",
              text: "Please use your NSoft Mail"
            });
          } else {
            this.$notify({
              group: "alerts",
              type: "error",
              title: "Unknown error occurred",
              text: "Please contact #team-systems"
            });
          }
        });
      }
    },
    mounted() {
      this.onWindowLoad();
    },
    beforeMount() {
       window.addEventListener("load", this.onWindowLoad);
      }
}
</script>

<style lang="scss" scoped>
.notifications{
  font-family: Rubik !important;
}
.login-container {
  display: grid;
  grid-template-columns: 1fr 65.97%;
  height: calc(100vh - 36px);
  .content {
    img {
      margin-top: 24px;
      margin-left: 112px;
    }
    h1 {
      margin: 300px 112px 74px 112px;
      font-family: Rubik;
      font-size: 48px;
      font-weight: 300;
      width: 238px;
      line-height: 1.17;
      color: #2e384d;
    }
    .googleLogin:no-button {
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.25);
    }
    .googleLogin:no-button {
      margin-left: 502px;
      cursor: pointer;
      margin-right: 112px;
      width: 240px;
      height: 50px;
      margin-right:100px;
      border-radius: 4px;
      box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.25);
      background-color: #ffffff;
      display: flex;
      transition: box-shadow 0.15s ease;
      align-items: center;
      
      // justify-content: center;
      .logo-container {
        margin-left: 5px;
        margin-top: 6px;
        margin-bottom: 5px;
        img {
          width: 51px;
          margin: 0;
          height: 42px;
        }
      }
      span {
        margin-left: 20px;
        font-family: Rubik;
        font-size: 15px;
        font-weight: 500;
        color: #69707f;
      }
    }
  }
  .background {
    background: url('../../assets/background.png') center no-repeat;
    background-size: cover;
    height: 100%;
  }
}
.footer {
  height: 36px;
  background-color: #2e384d;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 11px;
    font-family: Rubik;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #8798ad
  }
}
</style>
